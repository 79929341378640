import React, { useState,useEffect } from 'react';
import Container from '../../common/Container';
import { Form, Button, Row, Col, InputGroup, FormControl, Image, Card} from 'react-bootstrap';

const GeneralSettings = () => {
  const [emailNotifications, setEmailNotifications] = useState(false);
  const [privacy, setPrivacy] = useState('public');
  const [defaultVendorLogoUrl, setDefaultVendorLogoUrl] = useState(null);
  const [defaultProductIconUrl, setProductIconUrl] = useState(null);
  const [defaultCatalogIconUrl, setCatalogIconUrl] = useState(null);
  const [defaultUnknownIconUrl, setUnknownIconUrl] = useState("https://ik.imagekit.io/kotharitech/Lofaz/Lofaz-admin/lofaz_logo.png");
  const [formData, setFormData] = useState({
    facebook: '',
    instagram: '',
    twitter: '',
    linkedin: '',
});


// useEffect(()=>{
//   setDefaultVendorLogoUrl
// })

// event to upload an image
const handleImageUpload = (e, setImageState) => {
  const file = e.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageState(reader.result); // Update state with image data URL
    };
    reader.readAsDataURL(file); // Read file as data URL
  }
};

// event to handle social media links
  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
          ...prevData,
          [name]: value,
      }));
  };


  return (
    <Container> 
      <div className="bg-light bg-gradient container p-4 rounded-lg shadow-sm">
        <h4 className="font-weight-bold mb-4 text-dark">General Settings</h4>

        <section className="mb-5">
          <h4 className="font-weight-semibold rounded-lg mb-4">Store Details</h4>
          <Row className='my-4'>
            <Col md={3} >
              <Card className="shadow-sm p-3 mb-4 bg-white rounded-lg">
                <Form.Group controlId="storeLogo" className="">
                  <Form.Label className='ml-2'>Default Company Logo</Form.Label>
                  <div className=" align-items-center">
                    <Form.Control type="file" className="mx-2 rounded-lg" onChange={(e) => handleImageUpload(e,setUnknownIconUrl)} />
                    {defaultUnknownIconUrl && (
                      <Image src={defaultUnknownIconUrl} alt="Current Logo" rounded style={{objectFit:"contain", width:"159px", height: '159px', marginLeft: 'auto',marginRight:'auto',display:"block",marginTop:"10px"}}/>
                    )}
                  </div> 
                </Form.Group>
              </Card>
            </Col>
            <Col md={3}>
              <Card className="shadow-sm p-3 mb-4 bg-white rounded-lg">
                <Form.Group controlId="storeLogo" className="">
                  <Form.Label className='ml-2'>Default Vendor Icon</Form.Label>
                  <div className=" align-items-center">
                    <Form.Control type="file" className="mx-2" onChange={(e) => handleImageUpload(e,setDefaultVendorLogoUrl)}/>
                    { defaultVendorLogoUrl&& (
                      <Image src={defaultVendorLogoUrl} alt="Current Icon" rounded style={{objectFit:"contain",  width:"159px", height: '159px', marginLeft: 'auto',marginRight:'auto',display:"block",marginTop:"10px"}} />
                    )}
                  </div>
                  
                </Form.Group>
              </Card>
            </Col>
            <Col md={3}>
              <Card className="shadow-sm p-3 mb-4 bg-white rounded-lg">
                <Form.Group controlId="storeLogo" className="">
                  <Form.Label className='ml-2'>Default Product Icon</Form.Label>
                  <div className=" align-items-center">
                    <Form.Control type="file" className="mx-2" onChange={(e) => handleImageUpload(e,setProductIconUrl)}/>
                    {defaultProductIconUrl && (
                      <Image src={defaultProductIconUrl} alt="Current Icon" rounded style={{objectFit:"contain",  width:"159px", height: '159px', marginLeft: 'auto',marginRight:'auto',display:"block",marginTop:"10px" }} />
                    )}
                  </div>
                  
                </Form.Group>
              </Card>
            </Col>
            <Col md={3}>
              <Card className="shadow-sm p-3 mb-4 bg-white rounded-lg" >
                <Form.Group controlId="storeLogo" className="" >
                  <Form.Label className='ml-2'>Default Catalog Icon</Form.Label>
                  <div className=" align-items-center">
                    <Form.Control type="file" className="mx-2" onChange={(e) => handleImageUpload(e,setCatalogIconUrl)}/>
                    {defaultCatalogIconUrl && (
                      <Image className="default_icon" src={defaultCatalogIconUrl} alt="Current Icon" rounded style={{objectFit:"contain",width:"159px", height: '159px', marginLeft: 'auto',marginRight:'auto',display:"block",marginTop:"10px"}} />
                    )}
                  </div>
                  
                </Form.Group>
              </Card>
            </Col>
          </Row>
          <h4>Details</h4>
          <Row className="my-4">
            <Col md={4}>
              <Form.Group controlId="companyName" className='my-2'>
                <Form.Label>Company Name</Form.Label>
                <Form.Control type="text" placeholder="Enter company name" />
              </Form.Group></Col>
            
            <Col md={4}>
              <Form.Group controlId="mobileNo" className='my-2'>
                <Form.Label>Mobile No</Form.Label>
                <Form.Control type="tel" placeholder="Enter mobile number" />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="emailId" className='my-2'>
                <Form.Label>Email ID</Form.Label>
                <Form.Control type="email" placeholder="Enter email address" />
              </Form.Group></Col>
          </Row>
          <h4>Social Media</h4>
          <Row className="my-4">
            <Col md={3}>
              <Form.Group controlId="socialMediaLink" className='my-2'>
                <Form.Label>Facebook</Form.Label>
                <Form.Control type="url" placeholder="Enter Facebook username" />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group controlId="socialMediaLink" className='my-2'>
                <Form.Label>Instagram</Form.Label>
                <Form.Control type="url" placeholder="Enter Instagram username" />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group controlId="socialMediaLink" className='my-2'>
                <Form.Label>LinkedIn</Form.Label>
                <Form.Control type="url" placeholder="Enter LinkedIn username" />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group controlId="socialMediaLink" className='my-2'>
                <Form.Label>Twitter</Form.Label>
                <Form.Control type="url" placeholder="Enter twitter username" />
              </Form.Group>
            </Col>
          </Row>


          <Button variant="dark" type="submit" className='my-4'>Save Changes</Button>
        </section>
      </div>
    </Container>
  );
};

export default GeneralSettings;
