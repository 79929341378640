import React, { Component, useEffect } from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import DataTable from "react-data-table-component";
import axios from "axios";
import UserService from "./../../../services/user.service";
import { API_URL, APP_URL } from "../../../utils";
import Container from "./../../common/Container";
import StatusSwitch from "./../../common/StatusSwitch";
import Catalog, { getCatalogTableConfig } from "../Catalog/Catalog";
import Product,{ getProductTableConfig } from "../Product";
import Order,{ getOrderTableConfig } from "../Order";
import Customer,{ getCustomerTableConfig } from "../Customer/Customer";

// class components are set
class VendorDetails extends Component {
  state = {
    vendor: {},
    catalogs: [],
    products: [],
    customers: [],
    orders: [],
    settings: {},
    fetched: false,
    vendorNotFound: false,
  };

  // async function that fetches data from apis
  async componentDidMount() {
    const { match: { params: { user_id } } } = this.props;
  
    try {
      const [vendorResponse,vendorProducts, catalogData, productData, custData, orderData] = await Promise.all([
        axios.get(`${API_URL}/shop/admin/v1`, {
          headers: {
            Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTk0NDEyZGVhYzNjYzRmNTVlYjMwMjIiLCJpYXQiOjE3MTgyNzg0NzYsImV4cCI6MTc0OTgxNDQ3Nn0.jK6-AUgAQhPxcbpcBEQ0H2qGG_9vVbFH12OCSdFiaB0',
          },
        }),
        getCatalogTableConfig(this.props.history),
        getProductTableConfig(this.props.history),
        getCustomerTableConfig(this.props.history),
        getOrderTableConfig(this.props.history)
      ]);
  // console.log(vendorProducts,"vendorProducts")
      const vendorData = vendorResponse.data.vendor_data;
      const vendor = vendorData.find((v) => v._id === user_id);
      // console.log(vendor)
      if (!vendor) {
        this.setState({ vendorNotFound: true, fetched: true });
        return;
      }
  // console.log("productsData",productsData)
      const catalogs = catalogData.data?.filter((catalog) => catalog.user_id === user_id) || [];
      // console.log(catalogs,"catalogs")
      const products = productData.data?.filter((product) => product.user_id === user_id) || [];
      console.log(products,"products")
      const customers = custData.data?.filter((cust) => cust._id === user_id) || [];
      // console.log(customers,"customers")
      // console.log(ordersData,"orders data")
      const orders = orderData?.filter((order) => order._id === user_id) || [];
      // console.log(orders,"orders")
      this.setState({
        vendor,
        catalogs,
        products,
        customers,
        orders,
        fetched: true,
      });
    } catch (error) {
      console.error('Error fetching data:', error);
      this.setState({ catalogs: [], products: [], customers: [], orders: [] });
    }
  }
  

  render() {
    const { vendor, catalogs, products, customers, orders, settings, fetched, vendorNotFound } = this.state;
    // console.log("current state",this.state)
    console.log(products,"products")
    // console.log(vendor,"vendors")
    const { user_id } = this.props.match.params;
    const { history } = this.props;

    // setting the instances 
    const catalogInstance = new Catalog();
    const catalogColumns = catalogInstance.columns;
    // console.log(catalogColumns,"catalog column instance");

    const productInstance = new Product();
    const productColumns = productInstance.columns;
    // console.log(productColumns,"product columns instance");

    const orderInstance = new Order();
    const orderColumns = orderInstance.columns;
    // console.log(orderColumns,"order column instance",);

    const custInstance = new Customer();
    const custColumns = custInstance.columns;
    // console.log(custColumns,"customer columns instance");

    if (!fetched) {
      return (
        <Container>
          <div className="d-flex align-items-center justify-content-center vh-100">
            <div className="spinner-border text-body" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </Container>
      );
    }
    
    if (vendorNotFound) {
      return (
        <Container>
          <div className="pt-4 px-4">
            <h4 className="text-center">No vendor found with the given ID: {user_id}</h4>
          </div>
        </Container>
      );
    }

    return (
      <Container>
        <div className="pt-4 px-4">
          <h3>Vendor Details</h3>
          <h4 className="text-center">User Id: {user_id}</h4>
        </div>

        <div className="card container p-4 mt-3">
          <nav>
            <div className="nav nav-tabs" style={{ width: "100%",color:"black" }} id="nav-tab" role="tablist">
              <a
                className="nav-link active nav-link-flex"
                id="nav-profile-tab"
                data-toggle="tab"
                href="#nav-profile"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="true"
                style={{ color: 'black' }}
              >
                <p style={{ color: 'black' }}>Profile</p>
              </a>
              <a
                className="nav-link nav-link-flex"
                id="nav-catalog-tab"
                data-toggle="tab"
                href="#nav-catalog"
                role="tab"
                aria-controls="nav-catalog"
                aria-selected="false"
              >
                Catalogs ({catalogs.length})
              </a>
              <a
                className="nav-link nav-link-flex"
                id="nav-product-tab"
                data-toggle="tab"
                href="#nav-product"
                role="tab"
                aria-controls="nav-product"
                aria-selected="false"
              >
                Products ({products.length})
              </a>
              <a
                className="nav-link nav-link-flex"
                id="nav-cust-tab"
                data-toggle="tab"
                href="#nav-cust"
                role="tab"
                aria-controls="nav-cust"
                aria-selected="false"
              >
                Customers ({customers.length})
              </a>
              <a
                className="nav-link nav-link-flex"
                id="nav-order-tab"
                data-toggle="tab"
                href="#nav-order"
                role="tab"
                aria-controls="nav-order"
                aria-selected="false"
              >
                Orders ({orders.length})
              </a>
            </div>
          </nav>
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="nav-profile"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
            >
              <div className="card mb-4">
                <div className="card-header d-flex justify-content-between align-items-center" style={{ color: 'black' }}>
                  <h5 className="mb-0">Vendor Profile</h5>
                  <img
                    className="rounded-circle"
                    src={vendor.photo ? vendor.photo : "https://lofazweb.s3.ap-south-1.amazonaws.com/default/logo.png"}
                    alt={vendor.username}
                    height="60px"
                    width="60px"
                  />
                </div>
                <div className="card-body row">
                  <div className="col-12 col-md-4 mb-3" style={{ borderRight: "1px solid gray" , color:"black" }}>
                    <h6 className="text-uppercase text-center" style={{ color: 'black' }}>Contact Information</h6>
                    <div className="mt-4">
                      <p className="m-2"><strong>Name:</strong> {`${vendor.first_name}
                      ${vendor.surname_name}` || ""}</p>
                      <p className="m-2"><strong>Phone:</strong> <a href={`tel:${vendor.phone}`}>{vendor.phone || ""}</a></p>
                      <p className="m-2"><strong>Email:</strong> {vendor.email || ""}</p>
                      <p className="m-2"><strong>Username:</strong> <a href={`${APP_URL + vendor["username"] || "" }`} target="_blank" rel="noopener noreferrer">{vendor["username"] || ""}</a></p>
                      <p className="m-2"><strong>Business Name:</strong> {vendor.business_name || ""}</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mb-3" style={{ borderRight: "1px solid gray" }}>
                    <h6 className="text-uppercase text-center" style={{ color: 'black' }}>Address</h6>
                    <div className="mt-4">
                      <p className="m-2" style={{ color: 'black' }}><strong>Address Line 1:</strong> {vendor.address_line1 || " -"}</p>
                      <p className="m-2" style={{ color: 'black' }}><strong>Address Line 2:</strong> {vendor.address_line2 || "-"}</p>
                      <p className="m-2" style={{ color: 'black' }}><strong>City:</strong> {vendor.city || "-"}</p>
                      <p className="m-2" style={{ color: 'black' }}><strong>Zip:</strong> {vendor.zip || "-"}</p>
                      <p className="m-2" style={{ color: 'black' }}><strong>State:</strong> {vendor.state || "-"}</p>
                      <p className="m-2" style={{ color: 'black' }}><strong>Country:</strong> {vendor.country || "-"}</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mb-3">
                    <h6 className="text-uppercase text-center" style={{ color: 'black' }}>Additional Information</h6>
                    <div className="mt-4">
                      <p className="m-2" style={{ color: 'black' }}><strong>Created At:</strong> {new Date(vendor.createdAt).toLocaleString() || ""}</p>
                      <p className="m-2" style={{ color: 'black' }}><strong>Last Login:</strong> {new Date(vendor.updatedAt).toLocaleString() || ""}</p>
                      <p className="m-2" style={{ color: 'black' }}><strong>Account Status:</strong> {vendor.activate ? "Active" : "Inactive"}</p>
                      <div className="m-2" style={{ color: 'black' }}>
                        <p><strong>Shop Status:</strong>
                          <StatusSwitch
                            data={{ _id: user_id, shop_status: vendor.activate ? "true" : "false" }}
                            statusAttrName="shop_status"
                            ofWhat="VendorSettings"
                          />
                        </p>
                      </div>
                      <div className="m-2">
                        <p style={{ color: 'black' }}><strong>Order Status:</strong>
                          <StatusSwitch
                            data={{ _id: user_id, order_status: settings.order_status }}
                            statusAttrName="order_status"
                            ofWhat="VendorSettings"
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="nav-catalog"
              role="tabpanel"
              aria-labelledby="nav-catalog-tab"
            >
              <DataTable
                {...getCatalogTableConfig(history)}
                data={catalogs}
                columns={catalogColumns}
                fetched={fetched}
                pagination
                paginationServer
                paginationTotalRows={catalogs.length} // Total rows count
              />
            </div>
            <div
              className="tab-pane fade"
              id="nav-product"
              role="tabpanel"
              aria-labelledby="nav-product-tab"
            >
              <DataTable
                {...getProductTableConfig(history)}
                data={products}
                columns={productColumns}
                fetched={fetched}
                pagination
                paginationServer
                paginationTotalRows={products.length} // Total rows count
              />
            </div>
            <div
              className="tab-pane fade"
              id="nav-cust"
              role="tabpanel"
              aria-labelledby="nav-cust-tab"
            >
              <DataTable
                {...getCustomerTableConfig(history)}
                data={customers}
                columns={custColumns}
                fetched={fetched}
                pagination
                paginationServer
                paginationTotalRows={customers.length} // Total rows count
              />
            </div>
            <div
              className="tab-pane fade"
              id="nav-order"
              role="tabpanel"
              aria-labelledby="nav-order-tab"
            >
              <DataTable
                {...getOrderTableConfig(history)}
                data={orders}
                columns={orderColumns}
                fetched={fetched}
                pagination
                paginationServer
                paginationTotalRows={orders.length} // Total rows count
              />
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default VendorDetails;
