import React,{Component} from "react"
import axios from "axios"
import { API_URL} from "../../../utils";
import Container from "../../common/Container"
import StatusSwitch from "./../../common/StatusSwitch";

class ProductDetails extends Component {
    state={
        product:[],
        fetched:false,
        productDetailsNotFound:false,
    }
    async componentDidMount(){
        const{
            match:{ 
                params:{_id}
            }
        } = this.props;

        try{
            // fetching the productş details by cat id 
            const response = await axios.get(`${API_URL}vendor/catalog/product/single/${_id}`,{
                headers:{
                    Authorization:"Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTk0NDEyZGVhYzNjYzRmNTVlYjMwMjIiLCJpYXQiOjE3MTgyNzg0NzYsImV4cCI6MTc0OTgxNDQ3Nn0.jK6-AUgAQhPxcbpcBEQ0H2qGG_9vVbFH12OCSdFiaB0",
                }
            })
            console.log(response.data,"response data")
            const product = response.data.data
            console.log(product,"product")

            this.setState({
                    product,
                    fetched:true,
            })
        }catch(error){
            console.log("error fetching product data",error)
        }
    }
render(){

const {product} = this.state
const {_id} = this.props.match.params
// console.log( product.title?.upperCase(),"product")
const title = product.title || []
const logo = product.featured_img || []
const trends = product.trends || []
const description = product.desc || []   
const mrp = product.mrp || []   
const sales_price = product.sales_price || []   
const stock = product.stock || []   
const createdAt = product.createdAt 
? new Date(product.createdAt).toLocaleString() 
: "-";
const updatedAt = product.updatedAt 
? new Date(product.updatedAt).toLocaleString()
: "-";  
const views = product.views || []   
const activate = product.activate || []   
const status = product.status || []   
console.log("product namne",title)

   return(
    <Container>
        <div className="pt-4 px-4 flex-col">
            <h3>Product Details</h3>
            {/* <h4>Product Name :{`${title}`}</h4> */}
        </div>
        <div className="card mb-4">
                <div className="card-header d-flex justify-content-between align-items-center" style={{ color: 'black' }}>
                  <h5 className="mb-0"> {`${title}`}</h5>
                  <img
                    className="mr-10"
                    src={`${logo}` ? logo : "https://lofazweb.s3.ap-south-1.amazonaws.com/default/logo.png"}
                    alt={product.username}
                    height="60px"
                    width="60px"
                  />
                </div>
                <div className="card-body row">
                  <div className="col-12 col-md-4 mb-3" style={{ borderRight: "1px solid gray" , color:"black" }}>
                    <h6 className="text-uppercase text-center" style={{ color: 'black' }}>Description</h6>
                    <div className="mt-4">
                      <p className="m-2"><strong>Description:</strong> {description || "No Description"}</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mb-3" style={{ borderRight: "1px solid gray" }}>
                    <h6 className="text-uppercase text-center" style={{ color: 'black' }}>Details</h6>
                    <div className="mt-4">
                      <p className="m-2" style={{ color: 'black' }}><strong>Stock:</strong> {stock || ""}</p>
                      <p className="m-2" style={{ color: 'black' }}><strong>Mrp:</strong> {mrp || ""}</p>
                      <p className="m-2" style={{ color: 'black' }}><strong>Sales-Price:</strong> {sales_price || ""}</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mb-3">
                    <h6 className="text-uppercase text-center" style={{ color: 'black' }}>Additional Information</h6>
                    <div className="mt-4">
                      <p className="m-2" style={{ color: 'black' }}><strong>Created At:</strong> {createdAt || ""}</p>
                      <p className="m-2" style={{ color: 'black' }}><strong>Updated At:</strong> {updatedAt || ""}</p>
                      <p className="m-2" style={{ color: 'black' }}><strong>Views:</strong> {views || ""}</p>
                      <div className="m-2" style={{ color: 'black' }}>
                        <p><strong>Product Status:</strong>
                          <StatusSwitch
                            data={{ status:status ? "true" : "false" }}
                            statusAttrName="status"
                            ofWhat="customersSettings"
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
        </div>
    </Container>
        )
}   
}

export default ProductDetails