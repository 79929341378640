import React, { Component } from "react";
import DataTable from "react-data-table-component";
import axios from "axios"
import Container from "./../../common/Container";
import { API_URL,APP_URL } from "../../../utils";
import UserService from "./../../../services/user.service";
import StatusSwitch from "./../../common/StatusSwitch";
import Order,{ getOrderTableConfig } from "../Order";
import Customer,{ getCustomerTableConfig } from "./Customer";
import Catalog,{ getCatalogTableConfig } from "../Catalog/Catalog";
import Product,{ getProductTableConfig } from "../Product";

// states defined
class CustomerDetails extends Component {
  state = {
    orders: [],
    vendors:{}, // vendors
    catalogs: [],   //catalogs
    products: [],    //products
    customers: [], // customers
    settings: {},  // settings
    // wishlist_cart
    // cart
    fetched: false,
    customerNotFound:false,
  };

    async componentDidMount() {
    const {
      match: {
        params: { cust_id },
      },
    } = this.props;

    try {
          // fetchs customer data by id 
          const response = await axios.get(`${API_URL}customer/admin/`, {
            headers: {
              Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTk0NDEyZGVhYzNjYzRmNTVlYjMwMjIiLCJpYXQiOjE3MTgyNzg0NzYsImV4cCI6MTc0OTgxNDQ3Nn0.jK6-AUgAQhPxcbpcBEQ0H2qGG_9vVbFH12OCSdFiaB0',
            },
          });
          const data = response.data.data;
          console.log(data,"data by customer id")
        const customers = data.find((customers)=> customers._id === cust_id);
        console.log(customers)
        if(!customers){
          this.setState({customerNotFound: true, fetched: true })
          return;
        }  

        // Creating a new fetch to get customer order details by  customer id 
        const response1 =await axios.get(`${API_URL}admin/order/list/:cust_id`,{
          headers: {
            Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTk0NDEyZGVhYzNjYzRmNTVlYjMwMjIiLCJpYXQiOjE3MTgyNzg0NzYsImV4cCI6MTc0OTgxNDQ3Nn0.jK6-AUgAQhPxcbpcBEQ0H2qGG_9vVbFH12OCSdFiaB0',
          },
        });
        console.log(response1,"response order details as per customerid")
        const resData = response1.data
        console.log(resData,"order details as per customerid")
        
        const productData = await getProductTableConfig(this.props.history);
        const products = productData.data.filter((product) => product.cust_id === cust_id);
    
        const orderData = await getOrderTableConfig(this.props.history);
        const orders = orderData.filter((order) => order._id === cust_id);
    
        // Set all fetched data in one go after all async operations
        this.setState({
          customers,
          products,
          orders,
          fetched: true,
        });

// Creating a new fetch to get customer by id 

// const response1 =await axios.get(`${API_URL}/customer/`{
//   headers: {
//     Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTk0NDEyZGVhYzNjYzRmNTVlYjMwMjIiLCJpYXQiOjE3MTgyNzg0NzYsImV4cCI6MTc0OTgxNDQ3Nn0.jK6-AUgAQhPxcbpcBEQ0H2qGG_9vVbFH12OCSdFiaB0',
//   },
// });
// const resData = response1.data

      // Fetch product data with history
      // const productData = await getProductTableConfig(this.props.history);
      // if (Array.isArray(productData.data)) {
      //   const products = productData.data.filter((product) => product.cust_id === cust_id);
      //   if (products) {
      //     this.setState({ products });
      //   }
      // }
      //    // Fetch order data
      //    const orderData = await getOrderTableConfig(this.props.history);
      //    if (Array.isArray(orderData)) {
      //      const orders = orderData.filter((order) => order._id === cust_id);
      //     //  console.log(orders = orderData.filter((order) => order._id === cust_id)) 
      //      if (orders) {
      //        this.setState({ orders });
      //      }
      //    } else {
      //      console.error('Expected an array but got:', orderData);
      //      this.setState({ catalogs: [], products: [], customers:[], orders: [] });
      //    }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  render() {
    const { customers, orders, fetched, catalogs, products, customerNotFound, settings} = this.state;
    const { cust_id } = this.props.match.params;
    const { history } = this.props;
    console.log("orders Props",orders);
    console.log("customers Props",customers);
    // console.log("customer Props",customers);
    // console.log("products Props",products);

    
    const catalogInstance = new Catalog();
    const catalogColumns = catalogInstance.columns;
    // console.log(catalogColumns,"catalog column instance");

    const productInstance = new Product();
    const productColumns = productInstance.columns;
    // console.log(productColumns,"product columns instance");

    const orderInstance = new Order();
    const orderColumns = orderInstance.columns;
    // console.log(orderColumns,"order column instance",);

    const custInstance = new Customer();
    const custColumns = custInstance.columns;
    // console.log(custColumns,"customer columns instance");

    // Destructure  details from the first item in the `location` array
    // console.log(customers.profile?.location[0]?.address[0])
    const address1 = customers.profile?.location[0]?.address[0] || [];
    const address2 = customers.profile?.location[0]?.address[1] || [];
    const city = customers.profile?.location[0]?.city || "-";
    const state = customers.profile?.location[0]?.state || "-";
    const country = customers.profile?.location[0]?.country || "-";
    const zip = customers.profile?.location[0]?.zip_code || "-";
    const firstName =customers.profile?.name?.first || "-";
    // console.log(customers.profile?.name?.first);
    const lastName =customers.profile?.name?.last || "-";
    const phone =customers.profile?.phone || "-"
    const cart =customers?.total_cart || "0"
    const order=customers?.total_order || "0"
    const wishlist =customers?.total_wishlist || "0"
    // console.log(customers?.total_wishlist) 
    const shop=customers?.total_shop || "0"
    const customerStatus =customers.profile?.activate || "-"
    // console.log(customers.profile?.activate)
         const photo = customers.profile?.img[0] || [];
    const createdAt = customers.createdAt 
    ? new Date(customers.createdAt).toLocaleString() 
    : "-";
    const updatedAt = customers.updatedAt 
    ? new Date(customers.updatedAt).toLocaleString()
    : "-";
    const loginAt =customers.profile?.updatedAt? new Date(customers.profile.updatedAt).toLocaleString() :"-";
  

    if (!fetched) {
      return (
        <Container>
          <div className="d-flex align-items-center justify-content-center vh-100">
            <div className="spinner-border text-body" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </Container>
      );
    }
    // console.log(!Cust)
    if (customerNotFound) {
      return (
        <Container>
          <div className="pt-4 px-4">
            <h4 className="text-center">No customer found with the given ID: {cust_id}</h4>
          </div>
        </Container>
      );
    }

    return (
      <Container>
        <div className="pt-4 px-4">
          <h3>Customer Details</h3>
          <h4 className="text-center">User Id: {cust_id}</h4>
        </div>

        <div className="card container p-4 mt-3">
          <nav>
            <div className="nav nav-tabs" style={{ width: "100%" }} id="nav-tab" role="tablist">
              <a
                className="nav-link active nav-link-flex"
                id="nav-profile-tab"
                data-toggle="tab"
                href="#nav-profile"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="true"
                style={{ color: 'black' }}
              >
                <p style={{ color: 'black' }}>Profile</p>
              </a>
              <a
                className="nav-link  nav-link-flex"
                id="nav-cust-tab"
                data-toggle="tab"
                href="#nav-cust"
                role="tab"
                aria-controls="nav-cust"
                aria-selected="false"
              >
                 Order Details
              </a>
              <a
                className="nav-link  nav-link-flex"
                id="nav-wishlist-tab"
                data-toggle="tab"
                href="#nav-wishlist"
                role="tab"
                aria-controls="nav-wishlist"
                aria-selected="false"
              >
                 Wishlist 
              </a>
              <a
                className="nav-link  nav-link-flex"
                id="nav-cart-tab"
                data-toggle="tab"
                href="#nav-cart"
                role="tab"
                aria-controls="nav-cart"
                aria-selected="false"
              >
                 Cart
              </a>
              <a
                className="nav-link  nav-link-flex"
                id="nav-vendor-tab"
                data-toggle="tab"
                href="#nav-vendor"
                role="tab"
                aria-controls="nav-vendor"
                aria-selected="false"
              >
                 Vendor
              </a>
            </div>
          </nav>
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="nav-profile"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
            >
              <div className="card mb-4">
                <div className="card-header d-flex justify-content-between align-items-center" style={{ color: 'black' }}>
                  <h5 className="mb-0">Customer Profile</h5>
                  <img
                    className="rounded-circle"
                    src={customers.photo ? customers.photo : "https://lofazweb.s3.ap-south-1.amazonaws.com/default/logo.png"}
                    alt={customers.username}
                    height="60px"
                    width="60px"
                  />
                </div>
                <div className="card-body row">
                  <div className="col-12 col-md-4 mb-3" style={{ borderRight: "1px solid gray" , color:"black" }}>
                    <h6 className="text-uppercase text-center" style={{ color: 'black' }}>Contact Information</h6>
                    <div className="mt-4">
                      <p className="m-2"><strong>Name:</strong> {`${firstName}
                      ${lastName}` || ""}</p>
                      <p className="m-2"><strong>Phone:</strong> <a href={`tel:${phone}`}>{phone || ""}</a></p>
                      {/* <p className="m-2"><strong>Email:</strong> {customers.email || ""}</p> */}
                      {/* <p className="m-2"><strong>Username:</strong> <a href={`${APP_URL + customers["username"] || "" }`} target="_blank" rel="noopener noreferrer">{customers["username"] || ""}</a></p> */}
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mb-3" style={{ borderRight: "1px solid gray" }}>
                    <h6 className="text-uppercase text-center" style={{ color: 'black' }}>Address</h6>
                    <div className="mt-4">
                      <p className="m-2" style={{ color: 'black' }}><strong>Address Line 1:</strong> {address1 || ""}</p>
                      <p className="m-2" style={{ color: 'black' }}><strong>Address Line 2:</strong> {address2 || ""}</p>
                      <p className="m-2" style={{ color: 'black' }}><strong>City:</strong> {city || ""}</p>
                      <p className="m-2" style={{ color: 'black' }}><strong>Zip:</strong> {zip || ""}</p>
                      <p className="m-2" style={{ color: 'black' }}><strong>State:</strong> {state || ""}</p>
                      <p className="m-2" style={{ color: 'black' }}><strong>Country:</strong> {country || ""}</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mb-3">
                    <h6 className="text-uppercase text-center" style={{ color: 'black' }}>Additional Information</h6>
                    <div className="mt-4">
                      <p className="m-2" style={{ color: 'black' }}><strong>Created At:</strong> {createdAt || ""}</p>
                      <p className="m-2" style={{ color: 'black' }}><strong>Last Login:</strong> {loginAt || ""}</p>
                      <div className="m-2" style={{ color: 'black' }}>
                        <p><strong>Account Status:</strong>
                          <StatusSwitch
                            data={{customerStatus:customerStatus ? "true" : "false" }}
                            statusAttrName="customerStatus"
                            ofWhat="customersSettings"
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>   
            <div
              className="tab-pane fade"
              id="nav-cust"
              role="tabpanel"
              aria-labelledby="nav-cust-tab"
              aria-selcted="false"
            >
             <div
              className="tab-pane fade show "
              id="nav-profile"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
            >
              {/*customer order details */}
              <DataTable
                {...getOrderTableConfig(history)}
                data={orders}
                columns={orderColumns}
                fetched={fetched}
                pagination
                paginationServer
                paginationTotalRows={orders.length} // Total rows count
              />
            </div>   
              </div>
              {/*customer wishlist details */}
            <div
              className="tab-pane fade"
              id="nav-wishlist"
              role="tabpanel"
              aria-labelledby="nav-wishlist-tab"
              aria-selcted="false"
            >
             <div
              className="tab-pane fade show "
              id="nav-profile"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
            >
              <DataTable
                {...getOrderTableConfig(history)}
                data={orders}
                columns={orderColumns}
                fetched={fetched}
                pagination
                paginationServer
                paginationTotalRows={orders.length} // Total rows count
              />
            </div>   
              </div>
              {/*customer cart details */}
            <div
              className="tab-pane fade"
              id="nav-cart"
              role="tabpanel"
              aria-labelledby="nav-cart-tab"
              aria-selcted="false"
            >
             <div
              className="tab-pane fade show "
              id="nav-profile"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
            >
             <DataTable
                {...getOrderTableConfig(history)}
                data={orders}
                columns={orderColumns}
                fetched={fetched}
                pagination
                paginationServer
                paginationTotalRows={orders.length} // Total rows count
              />
            </div>   
              </div>
              {/*customer order details */}
            <div
              className="tab-pane fade"
              id="nav-vendor"
              role="tabpanel"
              aria-labelledby="nav-vendor-tab"
              aria-selcted="false"
            >
             <div
              className="tab-pane fade show "
              id="nav-profile"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
            >
             <DataTable
                {...getOrderTableConfig(history)}
                data={orders}
                columns={orderColumns}
                fetched={fetched}
                pagination
                paginationServer
                paginationTotalRows={orders.length} // Total rows count
              />
            </div>   
              </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default CustomerDetails;
