import React, { Component } from 'react';
import DataTable from "react-data-table-component";
import Papa from 'papaparse'
import axios from "axios";
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { saveAs } from "file-saver";
import CryptoJS from "crypto-js";
import Switch from '@material-ui/core/Switch';
import Toggle from '@material-ui/core/Switch';
import Container from "../../common/Container";
import AddButton from '../../common/AddButton';
import authHeader from "../../../services/auth-header";
import { API_URL,checkAuthed, cookieFetcher,APP_URL, VENDOR_PANEL_URL } from '../../../utils';

// button
const ViewProfileButton = ({
  viewUrl,
  handleEdit,
  handleDelete,
  vendorDetailsUrl,
  handleGuestLogin,
}) => (
  <div className="container">
    <div className="d-flex flex-row justify-center" style={{ gap: '7px' }}>
      <a
        className="btn btn-dark p-2"
        href={viewUrl}
        target="_blank"
        rel="noreferrer"
        title="View Store"
      >
        <span class="material-symbols-outlined font-bold">
          open_in_new
        </span>
      </a>
      {/* <Link
        className="btn btn-dark p-2"
        to={vendorDetailsUrl}
        title="View Details"
      >
        <i className="fas fa-eye" />
      </Link> */}
      <button className="btn btn-dark p-2" onClick={handleEdit} title="Edit">
        <i className="fas fa-pen" />
      </button>
      <button
        className="btn btn-danger p-2"
        onClick={handleDelete}
        title="Delete"
      >
        <i className="fas fa-trash" />
      </button>
    </div>
  </div>
);

// extracting  vendors data by category using get api 
export const getCategory = async (currentPage, limit, search = '', sort = 'createdAt', sortBy = -1) => {
  try {
    const authHeaderObject = authHeader();
    const response = await axios.get(`${API_URL}shop_category/admin`, {
      params: {
        page: currentPage,
        limit: limit,
        search: search,
        sort: sort,
        sortBy: sortBy
      },
      headers: {
        ...authHeaderObject,
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTk0NDEyZGVhYzNjYzRmNTVlYjMwMjIiLCJpYXQiOjE3MTgyNzg0NzYsImV4cCI6MTc0OTgxNDQ3Nn0.jK6-AUgAQhPxcbpcBEQ0H2qGG_9vVbFH12OCSdFiaB0'
      },
      mode: "cors",
      cache: 'default'
    });
    const data = response.data[0];
    console.log("Data fetched correctly", data[0]);
    return data;
  }
  catch (error) {
    console.error('Error fetching orders:', error);
    return { data: [], total_shop_category: 0 };
  }
};
// console.log(data)

// initialize states and class components
class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      currentPage: 1,
      limit: 10, // Initial rows per page
      totalRows: 0,
      fetched: false,
      search: '',
      sort: -1,
      sortBy: 'createdAt',
      // searchBy: 'title', // Default searchBy field set to 'title' (Product Name)
    };

    this.debouncedFetchData = _.debounce(this.fetchData, 300);
  }

  async componentDidMount() {
    const { currentPage, limit, search, sort, sortBy } = this.state;
    this.fetchData(currentPage, limit, search, sort, sortBy);
  }

  fetchData = async (currentPage, limit, search = '', sort = 1, sortBy = 'createdAt') => {
    try {
      const data = await getCategory(currentPage, limit, search, sortBy, sort);
      console.log("fetch", data.data);
      this.setState({ data: data.data, totalRows: data.total_shop_category, fetched: true });
    } catch (error) {
      console.log("Error occurred while fetching orders:", error);
    }
  }



  downloadAsXls = () => {
    const { data } = this.state;
    console.log("data", data);
    
    const columns = [
      { name: 'URL', selector: 'slug' },
      { name: 'CATEGORY TITLE', selector: 'title' },
      { name: 'SEO TITLE', selector: 'meta.title' },
      { name: 'SEO DESC', selector: 'meta.description' },
      { name: 'SEO KEYWORDS', selector: 'meta.keywords' },
      { name: 'isACTIVE?', selector: 'status' },
      { name: 'DATE CREATED', selector: 'createdAt' },
      { name: 'DATE UPDATED', selector: 'updatedAt' },
    ];
    
    const getNestedValue = (obj, path) => {
      const keys = path.split('.');
      let value = obj;
  
      keys.forEach((key, index) => {
        if (value && value[key] !== undefined) {
          value = value[key];
        } else {
          value = '';
        }
      });
  
      return value;
    };
  
    // Prepare header
    const header = columns.map(col => col.name);
  
    // Prepare data rows
    const csvData = data.map(row => {
      // Extract meta information
      const meta = row.meta.reduce((acc, item) => {
        acc[item.name] = item.content;
        return acc;
      }, {});
  
      return columns.map(col => {
        if (col.selector.startsWith('meta.')) {
          // Handle meta information
          return meta[col.selector.split('.')[1]] || '';
        }
        return getNestedValue(row, col.selector) || '';
      });
    });
  
    console.log("csvdata", csvData);
  
    // Combine header and data rows
    csvData.unshift(header);
    // Convert to CSV
    const csv = Papa.unparse(csvData);
    // Trigger download
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `VendorData_${new Date().toISOString().slice(0, 10)}.csv`);
  };
  

  handleDelete =async(id,title) =>{
    console.log("id",id)
    const token = cookieFetcher('token');
      console.log("token",token)
    const doDelete = window.confirm(
      `Are you sure you want to delete this ${title}?`
    );
    if (doDelete) {
    try {
      
      const authHeaderObject = authHeader();
    const resp=await axios.delete(`${API_URL}shop_category/admin/${id}`,
      {
        headers: {
          ...authHeaderObject,
          'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDBmNDdlNDU5NDliZmFkYTVjZDNkNTEiLCJpYXQiOjE3MTY3OTAxMjQsImV4cCI6MTc0ODMyNjEyNH0.otLzAFe7Ba8Qy8K7CNFTpajda7Yqwm23adJEvqu4vJs` // Replace with dynamic token retrieval
        }
      }
    )

    if (resp.status === 200) {
     alert(`${title} deleted successfully.`);
     
        window.location.reload();
      
    } else {
      alert('Error deleting category:', resp.statusText);
    }
    } catch (error) {
      alert("Error deleting category");
      console.log("error deleting",error);
    }
  }
    

  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      const { currentPage, limit, search, sort, sortBy } = this.state;
      console.log("on page change:", currentPage, limit, search, sort, sortBy);
      this.fetchData(currentPage, limit, search, sort, sortBy);
    });
  };

  handleSearchChange = (e) => {
    const search = e.target.value;
    this.setState({ search }, () => {
      const { currentPage, limit, search, sort, sortBy } = this.state;
      console.log("fetch params", currentPage, limit, search, sort, sortBy);
      this.debouncedFetchData(currentPage, limit, search, sort, sortBy);
    });
  };


  updateURLParams = () => {
    const { currentPage, limit, search, sort, sortBy } = this.state;
    const queryParams = new URLSearchParams({
      page: currentPage,
      limit,
      search,
      sort,
      sortBy
    }).toString();
    this.props.history.push(`?${queryParams}`);
  };

  getURLParams = () => {
    const searchParams = new URLSearchParams(this.props.location.search);
    const currentPage = parseInt(searchParams.get('page')) || 1;
    const limit = parseInt(searchParams.get('limit')) || 10;
    const search = searchParams.get('search') || '';
    const sort = parseInt(searchParams.get('sortBy')) || -1; 
    const sortBy = searchParams.get('sort') || 'createdAt';
    
    return { currentPage, limit, search, sort, sortBy };
  }

  updateActivate = async (status, id) => {
    try {
      const authHeaderObject = authHeader(); // Assuming this returns the necessary headers
      const response = await axios.patch(`${API_URL}vendor/activate/${id}`, { activate: status }, {
        headers: {
          ...authHeaderObject,
          'Authorization': 'Bearer YOUR_AUTH_TOKEN_HERE' // Replace with dynamic token retrieval
        }
      });

      //console.log(response.data); // Log the response data for debugging purposes
      const { currentPage, limit, search, sort, sortBy } = this.state;
      this.fetchData(currentPage, limit, search, sort, sortBy);
    } catch (err) {
      console.error('Error updating activation status:', err);
      // Handle the error appropriately, maybe show a notification to the user
    }
  };

  handleRowsPerPageChange = (newPerPage) => {
    this.setState({ currentPage: 1, limit: newPerPage }, () => {
      const { currentPage, limit, search, sort, sortBy } = this.state;
      this.fetchData(currentPage, limit, search, sort, sortBy);
    });
  };

  handleSortClick = (column) => {
    //console.log("Sorting by", column);
    this.setState(prevState => {
      const newSort = prevState.sort === column ? prevState.sortBy * -1 : 1;
      return { sort: column, sortBy: newSort, currentPage: 1 };
    }, () => {
      console.log(`Sorting by ${this.state.sort} in order ${this.state.sortBy}`);
      this.fetchData(this.state.currentPage, this.state.limit, this.state.search, this.state.sortBy, this.state.sort);
    });
  }

  columns = [
    { name: "SL", cell: (row, index) => <p className='text-center'>{(this.state.currentPage - 1) * this.state.limit + index + 1}</p>, width: '100px' },

    {
      name: 'Logo',
      cell: (row) => (
        <img
          src={
            row && row.image
              ? row.image[0].location
              : 'https://lofazweb.s3.ap-south-1.amazonaws.com/default/logo.png'
          }
          style={{ width: "40%" }}
        />
      ),
      minWidth: '200px',
    },
    {
      name: "Image alt", cell: (data) => {
        //console.log(data);
        return (<span>
          {data ? data.image[0].alt : ""}
        </span>)
      }, width: '250px'
    },
    {
      name: "URL",
      cell: (data) => {
        //console.log(data);
        return (
          <a href={`https://lofaz.com/category/${data.slug}`} target="_blank" rel="noopener noreferrer">
            <p className='text-center'>{data ? data.slug : ''}</p>
          </a>
        );
      },
      width: '200px'
    },
    {
      name: "Vendor Count",
      cell: (row) => (
        <p>{row.total_shop}</p>
      ), width: '200px',

    },
    {
      name: "SEO Title",
      cell: (data) => {
        // Find the meta object with the name "title"
        const seoTitle = data && data.meta ? data.meta.find(meta => meta.name === 'title') : null;
        return (
          <p className='text-center'>
            {seoTitle ? `${seoTitle.name} : ${seoTitle.content}` : '-'}
          </p>
        );
      },
      width: '250px'
    },
    {
      name: "SEO Desc",
      cell: (data) => {
        // Find the meta object with the name "description"
        const seoDesc = data && data.meta ? data.meta.find(meta => meta.name === 'description') : null;
        return (
          <p className='text-center'>
            {seoDesc ? `${seoDesc.name}: ${seoDesc.content}` : '-'}
          </p>
        );
      },
      width: '300px'
    },
    {
      name: "Status",
      cell: (row) => {
        //console.log(data);
        return (
          <p className='text-center'>{row && row.status === true ? 'True' : 'False'}</p>
        );
      },
      width: '200px'
    },
    //     {
    //       name: "Phone", cell: (row) => (
    //         <div className="d-flex justify-content-between align-items-center">
    //           <a href={'tel:' + (row && row.phone)}>{row && row.phone}</a>{' '}
    //           <a
    //             href={`https://api.whatsapp.com/send?phone=${encodeURIComponent(row.phone)}&text=${encodeURIComponent(`Hello ${row.full_name}, 👋🏻
    // Thank you for joining LOFAZ. 😊

    // 👉🏻 Here is your Website URL - Lofaz.com/${row.username}   
    // Share this URL with your friends & colleague so that they can place the order on your website 🚀

    // My name is Chirag and I’m reaching out from LOFAZ Support Team 👨🏻‍💻
    // Let us know if you need any help in settings up your Business 🏢

    // You can also call us or WhatsApp us any time on the same no +91 7272 8989 47

    // 🌟 Join the LOFAZ Seller Community!🌟
    // for the latest updates, E-commerce Tips, Customer attraction , and Expert strategies .🤩 
    // https://chat.whatsapp.com/BB9wXbHi2xm4b8liUlkDMM`)}
    // `}
    //             target="_blank"
    //             rel="noopener noreferrer"
    //           >
    //             <i className="fab fa-whatsapp-square fa-2x ml-2 wap"></i>
    //           </a>
    //         </div>),
    //       width: '200px', sortField: 'phone',
    //       sortable: true,
    //     },
    //     {
    //       name: "Category", cell: (data) => {
    //         //console.log(data);
    //         return (<p>{data.acc_category ? data.acc_category : ''}</p>)
    //       }, width: '150px',
    //       sortable: true,
    //       sortField: 'acc_category'
    //     },
    //     {
    //       name: "Catalogs", cell: (data) => {
    //         //console.log(data);
    //         return (<p>{data.total_catalog ? data.total_catalog : '0'}</p>)
    //       }, width: '150px',
    //       sortField: 'total_catalog',
    //       sortable: true,
    //     },
    //     {
    //       name: "Products", cell: (data) => {
    //         //console.log(data);
    //         return (<p>{data.total_product ? data.total_product : '0'}</p>)
    //       }, width: '150px',
    //       sortField: 'total_product',
    //       sortable: true,
    //     },
    //     {
    //       name: "Orders", cell: (data) => {
    //         //console.log(data);
    //         return (<p>{data.total_order ? data.total_order : '0'}</p>)
    //       }, width: '150px',
    //       sortable: true,
    //       sortField: 'total_order'
    //     },
    //     {
    //       name: "Featured",
    //       cell: row => {
    //         const handleToggle = () => {
    //           if (row) {
    //             const newActivateState = !row?.activate;
    //             this.updateActivate(newActivateState, row?._id);
    //           }
    //         };
    //         return (
    //           <div onClick={handleToggle} style={{ cursor: 'pointer' }}>
    //             {row?.activate ? (
    //               <span class="btnn btn-dark p-2 container material-symbols-outlined text-center mx-auto">
    //                 star
    //               </span>
    //             ) : (
    //               <span className="btnn material-symbols-outlined p-2 container text-center mx-auto px-auto" style={{ color: 'grey' }}>
    //                 star_outline
    //               </span>
    //             )}
    //           </div>
    //         );
    //       },
    //       width: '150px',
    //       sortField: 'activate',
    //       //sortable: true,

    //     },
    //     // {
    //     //   name: "Label", cell: (data) => {
    //     //     //console.log(data);
    //     //     return (<p>{datatotal_order ? datatotal_order : ''}</p>)
    //     //   }, width: '150px'
    //     // },
    {
      name: "Created On",
      selector: (row) => {
        const date = new Date(row.createdAt);
        const options = {
          weekday: 'short',
          month: 'short',
          day: '2-digit',
          year: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        };
        const formattedDate = date.toLocaleString('en-US', options)
          .replace(",", "");
        return formattedDate;
      },
      minWidth: '200px',
      //onClick: () => this.handleSortClick('createdAt'),
      sortField: 'createdAt',
      sortable: true,
      // sortFunction: {this.handleSortClick('createdAt')}}
      minWidth: '200px',

    },
    {
      name: "Updated On",
      selector: (row) => {
        const date = new Date(row.updatedAt);
        const options = {
          weekday: 'short',
          month: 'short',
          day: '2-digit',
          year: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        };
        const formattedDate = date.toLocaleString('en-US', options)
          .replace(",", "");
        return formattedDate;
      },
      minWidth: '200px',
      //onClick: () => this.handleSortClick('createdAt'),
      sortField: 'updatedAt',
      sortable: true,
      // sortFunction: {this.handleSortClick('createdAt')}}
      minWidth: '200px',

    },
    {
      name: 'Actions',
      cell: row => (
        <ViewProfileButton
          viewUrl={APP_URL + '/categories' + row.title}

          vendorDetailsUrl={`/vendor-category/${row._id}`}
          handleDelete={() => this.handleDelete(row._id,row.title)}
          handleGuestLogin={() => {
            const date = new Date();
            const url =
              VENDOR_PANEL_URL +
              'login?guest=' +
              encodeURIComponent(
                CryptoJS.AES.encrypt(
                  row && row.phone,
                  process.env.REACT_APP_GUEST_SECRET +
                  String(Number(date.getSeconds()) > 30 ? 0 : 1) +
                  date.getMinutes() +
                  date.getHours() +
                  date.getDay() +
                  date.getMonth() +
                  date.getFullYear()
                ).toString()
              );
            window.open(url, '_blank');
          }}
        />
      ),
      minWidth: '200px'
    },
    {
      name: 'Activate',
      cell: row => {
        const handleToggle = () => {
          const newActivateState = !row?.activate;
          this.updateActivate(newActivateState, row?._id);
        };
        //not using handleToggle since featured bhi toggle hota dono ka same API field hai
        return (
          <div>
            <Toggle
              defaultChecked={true}
              color="primary"
              name="activate-switch"
              inputProps={{ 'aria-label': 'activate switch' }}
            />
            {/* {row?.activate ? <span>Inactive</span> : <span>Active</span>} */}
          </div>
        );
      },
      minWidth: '200px'
    }
  ];


  // handleStatusChange = async (id, newStatus) => {
  //   // Make API call to update the status on the server
  //   try {
  //     await axios.patch(`${API_URL}catalog/${id}`, { active: newStatus }, {
  //       headers: authHeader(),
  //     });
  //     const updatedData = this.state.data.map(item =>
  //       item._id === id ? { ...item, active: newStatus } : item
  //     );
  //     this.setState({ data: updatedData });
  //   } catch (error) {
  //     console.error("Error updating catalog status:", error);
  //   }
  // };

  render() {
    const { data, fetched, currentPage, search, limit, totalRows } = this.state;

    const { history } = this.props;

    console.log("dataaaaaaaaaaaaa", data);
    console.log("fetcheddd", fetched);


    return (
      <Container>


        <div className="mb-3 d-flex mt-4 justify-content-start text-center">
          <div className="col-12 col-xl-3 col-md-1 mt-2"><h3>Vendor Categories</h3></div>
          <div className="col-12 col-xl-3 col-md-5">
            <input
              type="text"
              className="form-control"
              aria-label="select search by"
              placeholder="Search by business name"
              value={search}
              onChange={this.handleSearchChange}
            />
          </div>
          <AddButton label="New Category" handler={() => history.push('vendor-category/new')} />
          <button className="btn btn-dark col-12 col-md-1 mr-2" onClick={this.downloadAsXls}>
            <i className="fa fa-file-download" />
          </button>
        </div>

        <DataTable
          title=""
          // searchByList={[
          //   { label: "Catalog Name", value: "title" },

          //   // {
          //   //   label: "Vendor Business Name",
          //   //   value: ({ data }) => (data ? data["shop"]["business_name"] : ""),
          //   // },
          // ]}
          //   {
          //     label: "Customer Name",
          //     value: ({ customer }) => (customer ? customer["full_name"] : ""),
          //   },
          //   {
          //     label: "Product Name",
          //     value: ({ product }) => (product ? product["title"] : ""),
          //   },
          //   {
          //     label: "Vendor Mobile Number",
          //     value: ({ data }) => (data ? data["phone"] : ""),
          //   },
          //   {
          //     label: "Customer Mobile No",
          //     value: ({ customer }) => (customer ? customer["phone"] : ""),
          //   },
          // ]}
          {...getCategory(history)}


          data={data}
          columns={this.columns}
          pagination
          paginationServer
          paginationPerPage={limit} // Make sure this matches your 'limit' state
          paginationRowsPerPageOptions={[10, 15, 20, 30, 50, 100]} // Options for rows per page
          paginationTotalRows={totalRows} // Total rows count
          onChangePage={this.handlePageChange}
          onChangeRowsPerPage={this.handleRowsPerPageChange}
          sortServer // Indicate that sorting should be handled on the server side
          onSort={(column, direction) => this.handleSortClick(column.sortField)}

        />
      </Container>
    );
  }
}

export default Product;
