import React, { Component } from 'react';
import DataTable from "react-data-table-component";
import axios from "axios";
import _ from 'lodash';
import moment from "moment"
import Container from "../common/Container";
import AddButton from '../common/AddButton';
import authHeader from "../../services/auth-header";
import { checkAuthed, API_URL, handleDelete } from "../../utils";

// delete button
const ViewOrderButton = ({ handleDelete,handleEdit }) => (
  <div className="container">
    <div className="d-flex justify-center" >
      <button className="btn btn-dark p-2 m-2" onClick={handleEdit} title="Edit">
        <i className="fas fa-pen" />
      </button>
      <button className="btn btn-danger p-2 m-2" onClick={handleDelete}>
        <i className="fas fa-trash" />
      </button>
    </div>
  </div>
);

// fetching the data from api
export const getUnitTableConfig = async (currentPage, limit, search = '', sort = 'createdAt', sortBy = -1) => {
  try {
    const authHeaderObject = authHeader();
    const response = await axios.get(`${API_URL}unit/`, {
      params: {
        page: currentPage,
        limit: limit,
        search: search,
        sort: sort,
        sortBy: sortBy
      },
      headers: {
        ...authHeaderObject,
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTk0NDEyZGVhYzNjYzRmNTVlYjMwMjIiLCJpYXQiOjE3MTgyNzg0NzYsImV4cCI6MTc0OTgxNDQ3Nn0.jK6-AUgAQhPxcbpcBEQ0H2qGG_9vVbFH12OCSdFiaB0'
      },
      mode: "cors",
      cache: 'default'
    });
    // response
    const data = response.data.data;
    // console.log("Data fetched correctly", data);
    return data;
  }
  catch (error) {
    console.error('Error fetching orders:', error);
    return { unitData: [], totalCount: 0 };
  }
};

// creating a delete function


// setting order class components
class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unitData: [],
      currentPage: 1,
      limit: 10, // Initial rows per page
      totalRows: 0,
      fetched: false,
      search: '',
      sort: -1,
      sortBy: 'createdAt',
    };
    this.debouncedFetchData = _.debounce(this.fetchData, 300);
  }

  // async function componentDidMount 
  // fetchData method to load some data when the component first renders.
  async componentDidMount() {
    const { currentPage, limit, search, sort, sortBy } = this.state;
    this.fetchData(currentPage, limit, search, sort, sortBy);
  }
  fetchData = async (currentPage, limit, search = '', sort = 1, sortBy = 'createdAt') => {
    try {
      const data = await getUnitTableConfig(currentPage, limit, search, sortBy, sort);
      console.log("fetched", data);
      this.setState({ unitData: data, totalRows: data.totalCount, fetched: true });
    } catch (error) {
      console.log("Error occurred while fetching orders:", error);
    }
  }

  // Handles the event when the user changes the page in the table.
  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      const { currentPage, limit, search, sortBy, sort } = this.state;
      this.fetchData(currentPage, limit, search, sortBy, sort);
    });
  };

// Handles the search input change event, allowing users to search through the table data.
  handleSearchChange = (e) => {
    let search = e.target.value;
    this.setState({ search }, () => {
      const { currentPage, limit, search, sortBy, sort } = this.state;
      this.fetchData(currentPage, limit, search, sortBy, sort);
    });
  };

  //  Handles sorting when a user clicks on a column header to sort the table.
  handleSortClick = (column) => {
    //console.log("Sorting by", column);
    this.setState(prevState => {
      const newSort = prevState.sort === column ? prevState.sortBy * -1 : 1;
      return { sort: column, sortBy: newSort, currentPage: 1 };
    }, () => {
      console.log(`Sorting by ${this.state.sort} in order ${this.state.sortBy}`);
      this.fetchData(this.state.currentPage, this.state.limit, this.state.search, this.state.sortBy, this.state.sort);
    });
  }

  // Handles the event when the user changes the number of rows displayed per page.
  handleRowsPerPageChange = (newPerPage) => {
    this.setState({ currentPage: 1, limit: newPerPage }, () => {
      const { currentPage, limit, search, sortBy, sort } = this.state;
      //console.log(limit);
      this.fetchData(currentPage, limit, search, sortBy, sort);
    });
  };

  columns = [
    { name: "SL", cell: (row, index) => <p>{(this.state.currentPage - 1) * this.state.limit + index + 1}</p>, width: '100px' },
    { name: "Unit ID", cell: (row) => <p>{row["_id"]}</p>, width: '220px' },
    { name: "Unit", cell: (data) => <p>{data["unit"]}</p>, width: '150px' },
    {
      name: "Created At",
      cell: (data) => {
        const date = moment(data.createdAt);
        if (!date.isValid()) {
          return <p>Invalid Date</p>;
        }
        return <p>{date.format('ddd, MMM D, YYYY h:mm A')}</p>;
      },
      minWidth: '250px',
      sortField: 'createdAt',
      sortable: true,
    },
    {
      name: "Updated At",
      cell: (data) => {
       const date = moment(data.createdAt);
       if(!date.isValid()){
        return <p>Invalid Date</p>;
       }
      return <p>{date.format('ddd,MMM D,YYYY h:mm A')}</p>
      },
      minWidth: '250px',
      sortField: 'updatedAt',
      sortable: true,
    },
    {
      name:"Actions",
      cell:(data)=>(
        <div>
          <ViewOrderButton 
          handleDelete ={()=>
            handleDelete(
              data["_id"],
              "Unit",
              async()=>{
                const {currentPage,limit} =this.state;
                const data = await getUnitTableConfig(currentPage,limit);
                this.setState({data:data.catalog,totlaRows:data.total_customer});
              },
            )
            // minWidth:'150px',
          }
          />
        </div>
      )
    }
  ];

  render() {
    const { unitData, fetched, currentPage, search, limit } = this.state;

    const { history } = this.props;

    return (
      <Container>
        <div className="mb-3 d-flex mt-4 justify-content-start text-center">
          <div className="col-12 col-xl-6 col-md-5">
            <input
              type="text"
              className="form-control"
              aria-label="select search by"
              placeholder="Search"
              value={search}
              onChange={this.handleSearchChange}
            />
          </div>
          <AddButton label="Add New Unit" handler={() => history.push('units/new')} />
        </div>
        <DataTable
          title="Units"
          {...getUnitTableConfig(history)}
          data={unitData}
          columns={this.columns}
          pagination
          paginationServer
          paginationPerPage={limit} // Make sure this matches your 'limit' state
          paginationRowsPerPageOptions={[10, 15, 20, 30, 50]} // Options for rows per page
          paginationTotalRows={this.state.totalRows} // Total rows count
          onChangePage={this.handlePageChange}
          onChangeRowsPerPage={this.handleRowsPerPageChange}
          sortServer // Indicate that sorting should be handled on the server side
          onSort={(column, direction) => this.handleSortClick(column.sortField)}
        />
      </Container>
    );
  }
}

export default Order;
